<template>
    <div>
        <div class="fx-col fx-middle-row fx-center">
            <van-icon v-if="list.length > 1" :name="require('./icons/toggle_left.png')" size="16" @click="change('left')"></van-icon>
            <div class="qr-code__wrap fx-row">
                <div class="qr-code--image__wrap fx-center">
                    <van-image :src="prefixUrl + list[active]" height="200" width="200"></van-image>
                </div>
            </div>
            <van-icon v-if="list.length > 1" :name="require('./icons/toggle_right.png')" size="16" @click="change('right')"></van-icon>
        </div>
        <div v-if="list.length > 1" class="fx-center-middle" style="margin-top: 5px;color:#F4657D">
            {{`当前第${active + 1}张，共${list.length}张取票码`}}
        </div>
    </div>
</template>

<script>

export default {
    props: {
        list: Array
    },
    data() {
        return {
            active: 0,
            prefixUrl: 'https://api.fulijiayuan.com.cn/api/gainBarcode?content='
        }
    },
    methods: {
        change(direction) {
            if (direction == 'left') {
                if (this.active == 0) {
                    this.active = this.list.length - 1
                } else {
                    this.active--;
                }
            } else {
                if (this.active == this.list.length - 1) {
                    this.active = 0;
                } else {
                    this.active++;
                }
            }
        }
    },
    watch: {
        active: {
            immediate: true,
            handler(val) {

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.qr-code__wrap {
	width: 200px;
	overflow-x: auto;
	margin: 0 15px;
}
.qr-code--image__wrap {
	min-width: 120px;
}
</style>
