<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <div class="seat-legend--wrap  fx-row fx-center-middle">
                <div class="seat-legend--item fx-center fx-row">
                    <van-image :src="require('@/assets/images/seat/seat_sold.png')" fit="contain" height="20" width="20" />
                    <span>已售</span>
                </div>
                <div class="seat-legend--item fx-center fx-row">
                    <van-image :src="require('@/assets/images/seat/seat_selectable.png')" fit="contain" width="20" height="20" />
                    <span>可选</span>
                </div>
                <div class="seat-legend--item fx-center fx-row">
                    <van-image :src="require('@/assets/images/seat/seat_couple.png')" fit="contain" height="20" width="40" />
                    <span>情侣座</span>
                </div>
            </div>
        </div>
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <seat-map ref="seatMap" :data="seatList" :title="movie.roomName" :seat-area="areaMap" :max-select="opiDetail.maxCanBuy"></seat-map>
            </div>
        </div>
        <div class="footer-wrap">
            <div class="seat-legend--wrap fx-row fx-center-middle">
                <div class="seat-legend--item fx-center fx-row" v-for="key, index in areaMap.keys()" :key="index">
                    <van-image :src="seatImageList[0][parseInt(areaMap.get(key).index)].path" fit="contain" width="20" height="20"></van-image>
                    <span>￥{{areaMap.get(key).price}}</span>
                </div>
            </div>

            <div class="seat_selected-wrap">
                <div class="movie_name">{{movie.movieName}}</div>
                <div class="movie_play_time">{{$getCalendar(movie.playTime, 2)}} {{$momentFormatter(movie.playTime, 'HH:mm')}}-{{$momentFormatter(movie.closeTime, 'HH:mm')}}&nbsp;{{movie.edition}}</div>
                <van-divider class="divider"></van-divider>
                <div class="seat_selected fx-row fx-center">
                    <div class="seat_selected-item fx-row fx-center" v-for="item, index in selectedSeatList" :key="index">
                        <div class="seat_info fx-middle">
                            <span>{{item.name}}</span>
                            <span v-if="item.realPrice" class="fc-active">票价￥{{item.realPrice}}</span>
                            <span v-else class="fc-active">票价￥{{movie.price}}</span>
                        </div>
                        <div class="seat_delete fx-column fx-middle" @click="deleteSeat(item)">
                            <van-image :src="require('@/assets/images/movie/close_grey.png')" fit="cover" width="11"></van-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="seat_selected_confirm">
                <van-button v-if="selectedSeatList.length > 0 " type="primary" :color="$config.style.primaryColor" block round @click="confirm">{{totalPrice}}元 确认选座</van-button>
                <van-button v-else type="primary" :color="$config.style.primaryColor" block round @click="test" disabled>请先选座</van-button>
            </div>
        </div>
        <div class="utils-wrap">
            <van-overlay :show="showValidateSeatError" @click="showValidateSeatError = false" z-index="19">
                <div class="fx-center-middle" style="height: 100%;">
                    <div class="error-wrap fx-column fx-around">
                        <div class="fx-center">
                            <van-image :src="require('@/assets/images/seat/notice.png')" height="30" fit="cover"></van-image>
                        </div>
                        <div class="fx-row fx-middle">
                            <van-image :src="require('@/assets/images/seat/select_seat.png')" width="50" height="50"></van-image>
                            <van-image :src="require('@/assets/images/seat/empty_seat.png')" width="50" height="50"></van-image>
                            <van-image :src="require('@/assets/images/seat/select_seat.png')" width="50" height="50"></van-image>
                        </div>
                        <div class="fx-center">
                            <span class="error-tips">座位旁边不要留空</span>
                        </div>
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
import SeatMap from './seat-map/seat-map.vue'
import { getCinemaSeat, createOrder, validateSelectedSeatList, getCinemaFood } from '@api/cinema-request';
import seatImageList from './seat-image';
import { formatIntToMoney } from '@/libs/common-utils/common-utils';
import { mapGetters } from 'vuex';

export default {
    components: {
        SeatMap
    },
    data() {
        let movie = this.$cookies.get(this.$config.const.KEY_CURRENT_MOVIE);
        return {
            movie,
            areaMap: new Map(),
            seatImageList,
            opi: this.$route.query.opi,
            channel: this.$route.query.channel,
            opiDetail: {},
            seatList: [],
            cinemaFoodList: [],
            showValidateSeatError: false,
            innerWidth: window.innerWidth
        }
    },
    computed: {
        ...mapGetters([
            'memberInfo'
        ]),
        seatMapper() {
            let seatMapper = {}
            this.seatList.forEach((item) => {
                seatMapper[`${item.row}:${item.column}`] = item;
            })
            return seatMapper;
        },
        seatGrid() {
            if (!this.opiDetail) return { rowArray: [], columnArray: [] };
            let rowArray = [];
            let columnArray = [];
            for (let i = this.opiDetail.minRow; i <= this.opiDetail.maxRow; i++) {
                rowArray.push(i);
            }
            for (let i = this.opiDetail.minColumn; i <= this.opiDetail.maxColumn; i++) {
                columnArray.push(i);
            }
            return { rowArray, columnArray };
        },
        selectedSeatList() {
            return this.seatList.filter((item) => item.isSelected == true);
        },
        totalPrice() {
            let totalPrice = 0;
            let selectedSeatList = this.selectedSeatList;
            selectedSeatList.forEach((item) => {
                totalPrice += item.realPrice || this.movie.price;
            });
            return Number(totalPrice).toFixed(2);
        },
        selectedSeatOrderParams() {
            let seatIds = new Array();
            let seatNames = new Array();
            for (let item of this.selectedSeatList) {
                seatIds.push(item.id);
                seatNames.push(item.name);
            }
            return {
                seatIds: seatIds.join('|'),
                seatNames: encodeURI(seatNames).split(',').join('|')
            }
        },
        hasFood() {
            return this.cinemaFoodList && this.cinemaFoodList.length > 0 ? true : false;
        }
    },
    created() {
        this.getMovieCinemaSeat();
        this.getCinemaFood();
    },
    methods: {
        confirm() {
            let loadingToast = this.$toast.loading('订单创建中')
            this.validateSelectedSeatList().then(() => {
                this.createOrder().finally(() => {
                    loadingToast.clear();
                })
            }).catch(() => loadingToast.clear());
        },
        createOrder() {
            return new Promise((resolve, reject) => {
                let params = {
                    mobile: this.memberInfo?.mobile,
                    showId: this.movie.id,
                    seatIds: this.selectedSeatOrderParams.seatIds,
                    seatNames: this.selectedSeatOrderParams.seatNames,
                    channel:this.channel,
                }
                createOrder(params).then((res) => {
                    resolve();
                    if (this.hasFood) {
                        this.$goPage('order-food', {
                            orderId: res,
                            cinemaId: this.movie.cinemaId,
                        });
                    } else {
                        this.$goPage('order-pay', {
                            orderId: res
                        })
                    }

                }).catch(({ errorCode, errorMsg }) => {
                    reject();
                })
            });
        },
        validateSelectedSeatList() {
            return new Promise((resolve, reject) => {
                validateSelectedSeatList({ rowsseats: this.getSeatValidStr() }).then((res) => {
                    resolve();
                }).catch((error) => {
                    error.errorCode == '6040' ? this.showValidateSeatError = true : this.showValidateSeatError = false;
                    reject();
                })
            })
        },
        getSeatValidStr() {
            //1.座位留空问题
            let validateMap = {};
            let validStr;
            let seat;
            this.selectedSeatList.forEach((selectedSeatItem) => {
                for (let rowItem of this.seatGrid.rowArray) {
                    if (selectedSeatItem.row == rowItem) {
                        if (!validateMap[rowItem]) {
                            validStr = "";
                            for (let columnItem of this.seatGrid.columnArray) {
                                seat = this.seatMapper[`${rowItem}:${columnItem}`];
                                if (!seat) {
                                    validStr += 'O';
                                    continue;
                                }
                                if (seat.isSelected) validStr += 'B';
                                else validStr += (seat.status == 0 ? 'S' : 'A');
                            }
                            validateMap[rowItem] = validStr;
                        }
                    }
                }
            });

            return Object.values(validateMap).join('@');
        },
        getUnPaidOrder() {
            return new Promise((resolve, reject) => {
                this.$service.getUnPaidOrder().then((res) => {
                    if (res) {
                        resolve(res);
                    } else {
                        reject();
                    }
                }).catch(() => {
                    reject();
                })
            })
        },
        //远程请求所有座位信息
        getMovieCinemaSeat() {
            let loadingToast = this.$toast.loading(this.$config.loadingMsg);
            let data = {
                show_id: this.opi,
                channel: this.channel
            }
            getCinemaSeat(data).then((res) => {
                this.movie = res.show;
                this.buildAreaSeat(res.show);
                this.seatList = res.seatList;
                this.opiDetail = res.seatCount;
                loadingToast.clear();
            }).catch((err) => {
                loadingToast.clear();
            });
        },
        buildAreaSeat(show) {
            if (show.areaPriceList && show.areaPriceList.length > 0) {
                let areaMap = new Map();
                show.areaPriceList.forEach((item, index) => {
                    areaMap.set(item.areaId, { index, ...item });
                });
                this.areaMap = areaMap;
            }
            console.log('当前show ->', show)
            console.log('当前areaMap ->', this.areaMap)
        },
        test() {
            let selectedSeatList = this.seatList.filter((item) => item.isSelected == true);
        },
        getCinemaFood() {
            getCinemaFood(this.movie.cinemaId).then((res) => {
                this.cinemaFoodList = res;
            })
        },
        //点击选中座位列表中的关闭按钮 删除座位
        deleteSeat(seat) {
            this.$refs.seatMap.deleteSeat(seat);
        },
    }
}

/**
 * 将类似 a:1,b:2;a:2,b:4;a:3,b:6 的字符串 转换为对象集合
 * @param {转换源字符串} str 
 */
function convertStrToArrayBySplit(str) {
    let areaArray = [];
    let areaMap = new Map();
    if (str) {
        let splitStr = str.split(';');
        splitStr.forEach((item, index) => {
            let joinArray = [];
            const fields = item.split(',');
            for (let temp of fields) {
                const splitTemp = temp.split(':');
                if (splitTemp[1]) {
                    splitTemp[1] = `'${splitTemp[1]}'`;
                    joinArray.push(splitTemp.join(':'));
                }
            }
            let obj = null;
            eval(`obj = { ${joinArray.join(',')} }`);
            // if (areaMap.size >= 5) return;
            areaMap.set(obj.area.toString(), { index, price: obj.price });
            areaArray.push(obj);
        });
    }
    return { areaArray, areaMap };
}
</script>

<style lang="scss" scoped>
.header-wrap {
	.seat-legend--wrap {
		background-color: #fff;
	}
}

.body-wrap {
	position: relative;
}

.footer-wrap {
	width: 100%;
	padding: 0 12px;
	padding-top: 12vh;
	background-color: #eff1f8;
	z-index: 11;
	> div {
		margin-bottom: 12px;
		border-radius: 4px;
	}
	> div:not(:nth-last-child(1)) {
		padding: 5px 12px;
		background-color: #fff;
	}

	.seat-legend--wrap {
		background-color: transparent !important;
	}

	.seat_selected-wrap {
		.movie_name {
			font-size: 18px;
			// font-family: "pf medium";
			padding: 5px 0;
		}
		.seat_selected {
			overflow-x: scroll;
			width: 100%;
			@include scroll-bar-none();
			.seat_selected-item {
				word-break: keep-all;
				white-space: nowrap;
				background: $bg-color;
				border-radius: 4px;
				padding: 4px 0;
				.seat_info {
					padding: 0 10px;
					> span {
						display: inline-block;
						padding: 1px 0;
					}
				}
				.seat_delete {
					padding: 0 10px;
					height: 20px;
					border-left: 2px solid $border-color;
				}
			}
			.seat_selected-item:not(:nth-last-child(1)) {
				margin-right: 5px;
			}
		}

		.divider {
			margin: 8px 0;
		}
	}
}

.seat-legend--wrap {
	padding: 8px;

	.seat-legend--item {
		> span {
			margin-left: 3px;
		}

		&:not(:last-child) {
			margin-right: 15px;
		}
	}
}

.utils-wrap {
	/deep/ .error-wrap {
		height: 28%;
		width: 70%;
		background: rgba(0, 0, 0, 0.5);
		.error-tips {
			color: #fff;
			font-size: 16px;
		}
	}
}
</style>
