import Vue from 'vue';
import axios from "axios";
import store from '@/store';
import router from '@/router';
import config from '@/config';
import qs from "qs";
import md5 from "js-md5";
import globalConfig from "@/config";
import { Toast, Dialog } from "vant";
import { getTimestamp } from "@/libs/date-utils";
import { goPage } from '@/libs/vue-utils';


// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
axios.defaults.withCredentials = false;

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.queue = {};
    }
    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            timeout: globalConfig.requestTimeout,
            headers: {
                "Authorization": store.getters.authCode || '',
                "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
                // "Content-type": "application/json;charset=utf-8",
            },
        };
        return config;
    }
    interceptors(instance, url) {
        //请求拦截器
        instance.interceptors.request.use(
            (config) => {
                //清除加载框
                if (!Object.keys(this.queue).length) {
                    //
                }
                this.queue[url] = true;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        //响应拦截器
        instance.interceptors.response.use(
            (res) => {
                this.destroy(url);
                const { data, status } = res;
                if (status === 200) {
                    if (data.code === 200) {
                        return data.data;
                    } else {
                        return handleServerException(data);
                    }
                }
            },
            (error) => {
                this.destroy(url);
                return Promise.reject(error);
            }
        );
    }
    /**
     * 执行请求
     * @param {请求config 包含data以及config} options
     * @returns
     */
    request(options) {
        const instance = axios.create();
        options.method = options.method ? options.method : "POST";

        options = Object.assign(this.getInsideConfig(), options);
        // store.getters.authCode ? options.data.auth_code = store.getters.authCode : '';

        options.method.toUpperCase() === 'GET' ? options.params = options.data : void 0;

        options.data = qs.stringify(options.data);

        this.interceptors(instance, options.url);
        return instance(options);
    }
    /**
     * 签名加密
     * @param {请求参数data} data
     */
    encryption(data = {}) {
        let params = {
            ...data,
            appkey: store.getters.appKey,
            timestamp: getTimestamp(),
        };

        let paramsKeys = Object.keys(params).sort();

        let sortParams = {};
        paramsKeys.map((item) => {
            sortParams[item] = params[item];
        });

        let paramsStr = "";
        for (let key in sortParams) {
            paramsStr += `${key}=${sortParams[key]}&`;
        }
        paramsStr =
            paramsStr.substring(0, paramsStr.length - 1) + globalConfig.signKey;

        return {
            ...params,
            sign: md5(paramsStr).toUpperCase(),
        };
    }

    destroy(url) {
        delete this.queue[url];
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }
}

export default HttpRequest;

function handleServerException(data) {
    if (!data.success) {
        let errorCode = data.code;
        let errorMsg = data.msg;
        //处理相关操作
        switch (errorCode) {
            case '1001':
                //api用户不存在
                Toast.fail(errorMsg);
                store.commit('setAppKey', null);
                break;
            case '1003':
            case 403:
                //登录信息失效
                Toast.fail(errorMsg);
                store.commit('setAuthCode', false);
                router.push({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath,
                    }
                });
                break;
            case '6021':
                //未支付订单
                console.log(errorCode, errorMsg);
                break;
            case '6001':
                //订单不存在
                goPage('error404', null, true);
                break;
            case '9999': 
                break;
            default:
                console.log('HttpRequest -> handleServerException Default Bug')
                Toast.fail({
                        message: errorMsg,
                        duration: 2000 // 设置轻提示的显示时长为3000毫秒（3秒）
                      });
                break;
        }

        //这里默认返回固定错误消息体
        return Promise.reject({
            errorCode,
            errorMsg,
            data: data.data,
        });
    }
    return Promise.reject();
}
