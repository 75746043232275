import { render, staticRenderFns } from "./cinema-seat.vue?vue&type=template&id=702389c1&scoped=true"
import script from "./cinema-seat.vue?vue&type=script&lang=js"
export * from "./cinema-seat.vue?vue&type=script&lang=js"
import style0 from "./cinema-seat.vue?vue&type=style&index=0&id=702389c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702389c1",
  null
  
)

export default component.exports