<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <div class="search-wrap">
                <form action="/">
                    <van-search v-model="value" ref="search" placeholder="请输入要搜索的影院" show-action @search="onSearch" maxlength="18" autofocus @cancel="onCancel" shape="round"></van-search>
                </form>
            </div>
            <div class="search_history-wrap">
                <div class="sear_history-title fx-row fx-between">
                    <div>历史搜索</div>
                    <div class="fc-tips" @click="clearSearchHistory">清空</div>
                </div>
                <div class="search_history-item-wrap fx-row fx-wrap">
                    <template v-for="item, index in searchHistoryList">
                        <van-tag :key="index" class="search_history-item" color="#f3f4f6" text-color="#3f4956" closeable size="medium" @click="clickSearchHistoryItem(item)" @close="removeSearchHistory(item)">{{item}}</van-tag>
                    </template>
                </div>
            </div>
        </div>
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="search_result-item-wrap" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="0" ref="searchResult">
                    <div>
                        <van-cell-group>
                            <template v-for="item, index in searchResultList">
                                <van-cell :key="index" :title="item.name" :label="item.address" clickable @click="pickSearItem(item)"></van-cell>
                            </template>
                        </van-cell-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap"></div>
    </div>
</template>

<script>
import { getCinemaList } from '@api/cinema-request'
import { getStore, setStore, removeStore } from '@/libs/storage-utils';
import { mapGetters } from 'vuex';
import pagination from '@/components/mixins/pagination';

export default {
    mixins: [pagination],
    data() {
        return {
            value: '',
            movieId: this.$route.query.movieId,
            searchParams: null,
            searchHistoryList: getStore(this.$config.const.KEY_SEARCH_HISTORY_LIST),
            searchResultList: [],
        }
    },
    computed: {
        ...mapGetters([
            'currentCityCode'
        ])
    },
    methods: {
        clickSearchHistoryItem(item) {
            this.value = item;
            this.onSearch(this.value);
        },
        clearSearchHistory() {
            this.searchHistoryList = [];
            removeStore(this.$config.const.KEY_SEARCH_HISTORY_LIST);
        },
        removeSearchHistory(item) {
            let index = this.searchHistoryList.indexOf(item);
            if (index > -1) {
                this.searchHistoryList.splice(index, 1);
            }
            setStore(this.$config.const.KEY_SEARCH_HISTORY_LIST, this.searchHistoryList);
        },
        doSearch(searchParams) {
            searchParams.pageNo = this.pagination.pageNo;
            getCinemaList(searchParams).then((res) => {
                this.searchResultList = this.searchResultList.concat(res.records);
                this.copyPagination(res);
                this.searchParams = searchParams;
                this.$nextTick(() => {
                    if (this.$refs.searchResult.scrollHeight == this.$refs.searchResult.clientHeight) {
                        console.log('nextTick')
                        this.loadMore();
                    }
                })

            });
        },
        onSearch(val) {
            console.log('onSearch')
            this.getDefaultPagination();
            this.searchResultList = [];
            if (!val) return;
            let params = {
                name: val,
                cityCode: this.currentCityCode,
            }
            this.doSearch(params);

            if (this.searchHistoryList && this.searchHistoryList.length > 0) {
                let index = this.searchHistoryList.indexOf(val);
                if (index > -1) {
                    this.searchHistoryList.splice(index, 1);
                    this.searchHistoryList.unshift(val);
                } else {
                    this.searchHistoryList.unshift(val);
                }
            } else {
                this.searchHistoryList = new Array(val);
            }
            if (this.searchHistoryList.length > 6) {
                this.searchHistoryList.splice(6);
            }
            setStore(this.$config.const.KEY_SEARCH_HISTORY_LIST, this.searchHistoryList);
        },
        onCancel() {
            this.$goPage(-1);
        },
        loadMore() {
            console.log('loadMore')
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.doSearch(this.searchParams);
            }
        },
        pickSearItem(item) {
            let query = {
                cinemaId: item.id,
                movieId: this.movieId,
            }
            this.$goPage('movie-session', query);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.search.focus();
        })
    }
}
</script>

<style lang="scss" scoped>
.search_history-wrap {
	width: 100%;
	background-color: #fff;
	padding: 12px;
	.search_history-item-wrap {
		width: 100%;
		margin-top: 10px;
		.search_history-item {
			margin: 5px;
			/deep/ .van-tag__close {
				margin-left: 12px;
			}
		}
	}
}

.body-wrap {
	position: relative;
	.search_result-item-wrap {
		height: 100%;
		overflow-y: scroll;
	}
}
</style>
